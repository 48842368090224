import Queue from '../../queue';
import { h } from './component/element';
import DataProxy from './core/data_proxy';
import Sheet from './component/sheet';
import Bottombar from './component/bottombar';
import { cssPrefix } from './config';
import { locale } from './locale/locale';
import './index.less';


function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}
class Spreadsheet {
  constructor(selectors, options = {}) {
    this.dataQueue = new Queue();
    let targetEl = selectors;
    this.options = { showBottomBar: true, ...options };
    this.sheetIndex = 1;
    this.datas = [];
    this.allSheets = null;
    if (typeof selectors === 'string') {
      targetEl = document.querySelector(selectors);
    }
    this.bottombar = this.options.showBottomBar ? new Bottombar(() => {
      if (this.options.mode === 'read') return;
      const d = this.addSheet();
      this.sheet.resetData(d);
    }, (index) => {
      const d = this.datas[index];
      this.sheet.resetData(d);
    }, () => {
      this.deleteSheet();
    }, (index, value) => {
      const oldName =   this.datas[index].name;
      this.datas[index].name = value;
      // Update allSheets with the new name
      this.allSheets[index].name = value;
      this.sheet.trigger('rename-sheet', {oldName:oldName,newName: value, sheets: this.allSheets });
      // this.sheet.trigger('change');
    }) : null;
    this.data = this.addSheet();
    const rootEl = h('div', `${cssPrefix}`)
      .on('contextmenu', evt => evt.preventDefault());
    // create canvas element
    targetEl.appendChild(rootEl.el);
    this.sheet = new Sheet(rootEl, this.data, this.bottombar);
    if (this.bottombar !== null) {
      rootEl.child(this.bottombar.el);
    }
    const addEl = document.getElementsByClassName('x-spreadsheet-icon-img add');
    // console.log(addEl);
    if (addEl) {
      addEl[0].addEventListener('click', () => {
        this.sheet.trigger('add-sheet', this.datas.length);
      }, false);
    }
    // const xsheet = document.getElementsByClassName('x-spreadsheet-sheet')[0];
    // console.log(xsheet);
    // const selectAll = document.createElement('DIV');
    // selectAll.setAttribute('class', 'x-spreadsheet-select-all');
    // xsheet.appendChild(selectAll);
    // if (selectAll) {
    //   selectAll.addEventListener('click', () => {
    //     // this.sheet.trigger('add-sheet', this.datas.length);
    //     console.log(this.sheet.selector.indexes);
    //     // this.sheet.selector.set(-1, -1);
    //     // this.sheet.selectorSet(false, -1, -1, false);
    //     // this.sheet.selector.set(this.sheet.selector.indexes[0],
    //     //   this.sheet.selector.indexes[1]);
    //   }, false);
    // }
  }

  addSheet(name, active = true, isAddSheetBtnClick = false) {
    const n = name || `Sheet ${this.sheetIndex}`;
    const d = new DataProxy(n, this.options);
    if (this.sheet)
      this.sheet.setAllSheets(this.allSheets)

    d.change = debounce((...args) => {
      if (d.pivotData.isEdit) {
        args[0].pivotData = d.pivotData;
        this.sheet.trigger('change', ...args);
      } else {
        this.sheet.trigger('change', ...args);
      }
    }, 600);
    this.datas.push(d);
    // console.log('d:', n, d, this.datas);
    if (this.bottombar !== null) {
      this.bottombar.addItem(n, active, this.options);
      // if (this.sheet !== undefined) {
      //   this.sheet.trigger('add-sheet');
      // }
      // let count = 0;
      // const csIndex = this.sheetIndex;
      if (this.sheet !== undefined && !isAddSheetBtnClick) {
        let newSheetData = this.datas?.[this.datas.length - 1] || null
        if (newSheetData) {
          let payload = newSheetData.getData();
          this.sheet.trigger('change', { ...payload });
        }
      }
    }
    this.sheetIndex += 1;
    if (this.sheet !== undefined) {
      // console.log(this.datas.length);
      // ('x-spreadsheet-icon-img add').click(function () {
      //   this.sheet.trigger('add-sheet', datasLength);
      // });
    }
    return d;
  }

  deleteSheet() {
    if (this.bottombar === null) return;

    const [oldIndex, nindex] = this.bottombar.deleteItem();
    if (oldIndex >= 0) {
      const oldSheet = this.datas[oldIndex] || null;
      if (oldSheet) {
        const name = oldSheet.name;
        this.datas.splice(oldIndex, 1);
        const sheets = this.datas.map((data) => data.getData());
        this.allSheets = sheets;
        this.sheet.trigger('delete-sheet', { sheetName: name, sheets: sheets });
        if (nindex >= 0) this.sheet.resetData(this.datas[nindex]);
      }
    }
  }

  loadData(data) {
    const ds = Array.isArray(data) ? data : [data];
    if (this.bottombar !== null) {
      this.bottombar.clear();
    }
    this.datas = [];
    if (ds.length > 0) {
      this.allSheets = ds;
      for (let i = 0; i < ds.length; i += 1) {
        const it = ds[i];
        const nd = this.addSheet(it.name, i === 0, true);
        nd.setData(it);
        if (i === 0) {
          this.sheet.resetData(nd);
        }
      }
    }
    if (ds.length == 0) {
      const editIcon = document.getElementById("edit-pivot-table");
      if (editIcon) {
        editIcon.style.display = "none";
      }
    }
    return this;
  }

  getData() {
    return this.datas.map(it => it.getData());
  }

  cellText(ri, ci, text, sheetIndex = 0) {
    this.datas[sheetIndex].setCellText(ri, ci, text, 'finished');
    return this;
  }

  cell(ri, ci, sheetIndex = 0) {
    return this.datas[sheetIndex].getCell(ri, ci);
  }

  cellStyle(ri, ci, sheetIndex = 0) {
    return this.datas[sheetIndex].getCellStyle(ri, ci);
  }

  reRender() {
    this.sheet.table.render();
    return this;
  }

  on(eventName, func) {
    this.sheet.on(eventName, func);
    return this;
  }

  validate() {
    const { validations } = this.data;
    return validations.errors.size <= 0;
  }

  change(cb) {
    this.sheet.on('change', cb);
    return this;
  }

  static locale(lang, message) {
    locale(lang, message);
  }
}

const spreadsheet = (el, options = {}) => new Spreadsheet(el, options);

if (window) {
  window.x_spreadsheet = spreadsheet;
  window.x_spreadsheet.locale = (lang, message) => locale(lang, message);
}

export default Spreadsheet;
export {
  spreadsheet,
};
