import React, { useEffect, useState } from 'react';
import classes from './ChatBox.module.css';
import { Chats } from './Chats/Chats';
import chatIcon from '../../assets/chat-icon.svg';

export const ChatBox = () => {
 const [toggleChat,setToggleChat] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const handleToggleChat = () => {
    setToggleChat(!toggleChat);
    if (toggleChat) {
      // * Reset message count when opening the chat
      setMessageCount(0);
    }
  }; 
  const incMessageCounter=()=>{
    setMessageCount(messageCount+1)
  }
  return (
    <>
      <div className={classes['chat-box-container']} style={{ visibility: toggleChat ? 'visible' : 'hidden' }}>
        <div className={classes['chat-togglebar']} onClick={handleToggleChat}>
          Chat 
        </div>
        <Chats onNewMessage={incMessageCounter}/>
      </div>
      {/* Always render the chat-toggle-icon-container to update the counter */}
        {!toggleChat &&
          <div className={classes['chat-toggle-icon-container']} onClick={handleToggleChat}>
        <img className={classes['chat-toggle-icon']} src={chatIcon} width={50} />
        {messageCount > 0 && !toggleChat && <span className={classes['message-counter']}>{messageCount}</span>}
      </div>}
    </>
  ) 
}
