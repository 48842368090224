// import _ from 'lodash';
// import formulajs from '@formulajs/formulajs';
import { expr2cellRangeArgs, expr2xyOLD, xy2exprOLD } from './alphabet';
// import { numberCalc } from './helper';
// import { formulam } from './formula';

// VLOOKUP function
const VLOOKUP = (needle, table, index, rangeLookup) => {
  if (!table || !index) {
    return '#N/A';
  }
  const rangeLookUp = !(rangeLookup === 0 || rangeLookup === false);
    let result = '#N/A';
  const isNumberLookup = (typeof needle === 'number');
  let exactMatchOnly = false;
  for (let i = 0; i < table.length; i += 1) {
    const row = table[i];
    if (row[0] === needle) {
      result = (index < (row.length + 1) ? row[index - 1] : '#REF');
      break;
    } else if (!exactMatchOnly && ((isNumberLookup && rangeLookUp && row[0] <= needle)
      || (rangeLookUp && typeof row[0] === 'string' && row[0].localeCompare(needle) < 0))) {
      result = (index < (row.length + 1) ? row[index - 1] : '#REF');
    }
    if (isNumberLookup && row[0] > needle) {
      exactMatchOnly = true;
    }
  }
  return result;
};

// A1:A5 => A1,A2,A3,A4,A5
const infixExprToSuffixExpr = (src) => {
  const operatorStack = [];
  const stack = [];
  let subStrs = []; // SUM, A1, B2, 50 ...
  let fnArgType = 0; // 1 => , 2 => :
  let fnArgOperator = '';
  let fnArgsLen = 1; // A1,A2,A3...
  let oldc = '';
  for (let i = 0; i < src.length; i += 1) {
    const c = src.charAt(i);
    if (c !== ' ') {
      if (c >= 'a' && c <= 'z') {
        subStrs.push(c.toUpperCase());
      } else if ((c >= '0' && c <= '9') || (c >= 'A' && c <= 'Z') || c === '.') {
        subStrs.push(c);
      } else if (c === '"') {
        i += 1;
        while (src.charAt(i) !== '"') {
          subStrs.push(src.charAt(i));
          i += 1;
        }
        stack.push(`"${subStrs.join('')}`);
        subStrs = [];
      } else if (c === '-' && /[+\-*/,(]/.test(oldc)) {
        subStrs.push(c);
      } else {
        if (c !== '(' && subStrs.length > 0) {
          stack.push(subStrs.join(''));
        }
        if (c === ')') {
          let c1 = operatorStack.pop();
          if (fnArgType === 2) {
            // fn argument range => A1:B5
            try {
              const [ex, ey] = expr2xyOLD(stack.pop());
              const [sx, sy] = expr2xyOLD(stack.pop());
              let rangelen = 0;
              for (let x = sx; x <= ex; x += 1) {
                for (let y = sy; y <= ey; y += 1) {
                  stack.push(xy2exprOLD(x, y));
                  rangelen += 1;
                }
              }
              stack.push([c1, rangelen]);
            } catch (e) {
              // console.log(e)
            }
          } else if (fnArgType === 1 || fnArgType === 3) {
            if (fnArgType === 3) stack.push(fnArgOperator);
            // fn argument => A1,A2,B5
            stack.push([c1, fnArgsLen]);
            fnArgsLen = 1;
          } else {
            while (c1 !== '(') {
              stack.push(c1);
              if (operatorStack.length <= 0) break;
              c1 = operatorStack.pop();
            }
          }
          fnArgType = 0;
        } else if (c === '=' || c === '>' || c === '<') {
          const nc = src.charAt(i + 1);
          fnArgOperator = c;
          if (nc === '=' || nc === '-') {
            fnArgOperator += nc;
            i += 1;
          }
          fnArgType = 3;
        } else if (c === ':') {
          fnArgType = 2;
        } else if (c === ',') {
          if (fnArgType === 3) {
            stack.push(fnArgOperator);
          }
          fnArgType = 1;
          fnArgsLen += 1;
        } else if (c === '(' && subStrs.length > 0) {
          // function
          operatorStack.push(subStrs.join(''));
        } else {
          // priority: */ > +-
          if (operatorStack.length > 0 && (c === '+' || c === '-')) {
            let top = operatorStack[operatorStack.length - 1];
            if (top !== '(') stack.push(operatorStack.pop());
            if (top === '*' || top === '/') {
              while (operatorStack.length > 0) {
                top = operatorStack[operatorStack.length - 1];
                if (top !== '(') stack.push(operatorStack.pop());
                else break;
              }
            }
          } else if (operatorStack.length > 0) {
            const top = operatorStack[operatorStack.length - 1];
            if (top === '*' || top === '/') stack.push(operatorStack.pop());
          }
          operatorStack.push(c);
        }
        subStrs = [];
      }
      oldc = c;
    }
  }
  if (subStrs.length > 0) {
    stack.push(subStrs.join(''));
  }
  while (operatorStack.length > 0) {
    stack.push(operatorStack.pop());
  }
  return stack;
};

// Reshapes the array from 1D to 2D
const reshapeArray = (arr, dim) => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, dim));
  return newArr;
};

// Parses into Boolean
const parseBool = (bool) => {
  if (typeof bool === 'boolean') {
    return bool;
  }

  if (bool instanceof Error) {
    return bool;
  }

  if (typeof bool === 'number') {
    return bool !== 0;
  }

  if (typeof bool === 'string') {
    const up = bool.toUpperCase();
    if (up === 'TRUE') {
      return true;
    }

    if (up === 'FALSE') {
      return false;
    }
  }

  if (bool instanceof Date && !Number.isNaN(bool)) {
    return true;
  }
  return -1;
};

// Checks if string has Alphabets
const isAlpha = ch => typeof ch === 'string' && ((ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z'));

// Function to evaluate formula with LookUp
const evalLookUpExpr = (stack, getCellText) => {
  let table;
  // Formula as string
  const formulaLookUp = stack.slice(-1)[0][0];
  const arrExpr2xy = [];
  let tableDimensions;
  let cellTextVal;
  if (formulaLookUp === 'VLOOKUP' || formulaLookUp === 'HLOOKUP') {
    // Cell Range selection
    const arrValueRange = stack.slice(1, 3);
    const sCellVal = arrValueRange[0];
    const eCellVal = arrValueRange[1];
    // Converts expr A1 => (0, 0)
    const [sx, sy] = expr2xyOLD(sCellVal);
    const [ex, ey] = expr2xyOLD(eCellVal);
    const startDims = [sx, sy];
    const endDims = [ex, ey];
    // Calculation for 2D array dimensions => RxC
    const calcDimensions = endDims.map((n, i) => n - startDims[i]);
    const [dx, dy] = calcDimensions;
    tableDimensions = {
      tableRows: dy + 1,
      tableCols: dx + 1,
    };
    // Template text to insert into infixExprToSuffixExpr with range.
    const cellRangeStr = `ALIPOOPY(${sCellVal}:${eCellVal})`;
    const getRangeExpr = infixExprToSuffixExpr(cellRangeStr);
    const arrRangeExpr = getRangeExpr.slice(0, -1);
    // [A1...] => [(0, 0)...]
    arrRangeExpr.forEach(val => arrExpr2xy.push(expr2xyOLD(val)));
  }
  if (!(arrExpr2xy.length === 0)) {
    // Replaces cell with its value
    cellTextVal = arrExpr2xy.map((it) => {
      const [x, y] = it;
      return getCellText(x, y);
    });
    // [A1, A2, B1, B2]
    const untransTable = reshapeArray(cellTextVal, tableDimensions.tableRows);
    if (formulaLookUp === 'HLOOKUP') {
      table = untransTable;
    } else {
      // [A1, A2, B1, B2] => [A1, B1, A2, B2]
      const transTable = untransTable[0].map((_, colIndex) => untransTable
        .map(row => row[colIndex]));
      table = transTable;
    }
    let needle;
    const [needleInput] = stack;
    if (isAlpha(needleInput)) {
      needle = needleInput;
      needle.replace(/\w\S*/g, txt => txt.charAt(0)
        .toUpperCase() + txt.substr(1).toLowerCase());
    } else {
      needle = needleInput;
    }
    const index = Number(stack[stack.length - 3]);
    const rangeLookUp = parseBool(stack[stack.length - 2]);
    // LookUp function
    let needleValue = getNeedleRangeValue(needle, getCellText);
    const toEvalLookUp = VLOOKUP(needleValue, table, index, rangeLookUp);
    // const toEvalLookUp = VLOOKUP(needle, table, index, rangeLookUp);
    return toEvalLookUp;
  }
  return -1;
};

// formulaParser is a Parser object from the hot-formula-parser package
const cellRender = (src, formulaParser, getCellText, data) => {
  // If cell contains a formula, recursively parse that formula to get the value
  if (src.length > 0 && src[0] === '=') {
    if (!(src.search('LOOKUP') === (-1))) {
      let isValidSrc = validateLookupFunction(src);
      const isOtherSheet = src.includes('!');
      if (isValidSrc === 'OK' && !isOtherSheet) {
        const stack = infixExprToSuffixExpr(src.substring(1));
        const resLookUp = evalLookUpExpr(stack, getCellText);
        return resLookUp;
      }
      else if (isValidSrc === 'OK' && isOtherSheet) {
        const updatedInput = exctractDataFromOtherSheets(src, data);
        const result = VLOOKUP(updatedInput.needleValue, updatedInput.tableData, updatedInput.index, updatedInput.isSort)
        return result;
      }
      return isValidSrc;
    }
    const sheetReferenceRegex = /([A-Za-z0-9_]+)!([A-Za-z]+[0-9]+)(:?[A-Za-z]+[0-9]+)?/g;
    const isOtherSheet = src.includes('!');
    if (isOtherSheet) {
      const sheetReferences = src.slice(1).match(sheetReferenceRegex);
      if (sheetReferences && sheetReferences.length === 1) {
        const replaceData = exctractDataFromOtherSheets(sheetReferences[0], data);
        if (replaceData.length === 1) {
          return replaceData;
        }
      }
      let replaceData = src.slice(1).replace(sheetReferenceRegex, (match) => {
        const dataa = exctractDataFromOtherSheets(match, data);
        return dataa;
      });
      src = '=' + replaceData;
    }
    const parsedResult = formulaParser.parse(src.slice(1));
    const recursedSrc = (parsedResult.error) ? parsedResult.error : parsedResult.result;
    const parsedResultRecurse = cellRender(recursedSrc, formulaParser);
    return parsedResultRecurse;
  }
  // If cell doesn't contain a formula, render its content as is
  return src;
};

const exctractDataFromOtherSheets = (inputText, data) => {
  const respectiveData = [];
  const [sheetName, dataRange] = inputText.split('!');
  const cellRangeArgs = expr2cellRangeArgs(dataRange.toUpperCase());
  if (!(inputText.search('LOOKUP') === (-1))) {
    const argsString = inputText.substring(inputText.indexOf('(') + 1, inputText.indexOf(')'));
    const args = argsString.split(',');
    let needleValueArg = args[0];
    let tableDataArg = args[1];
    let needleValueSheet = getRespectiveSheet(needleValueArg, data);
    let tableSheet = getRespectiveSheet(tableDataArg, data);
    let needleValue;
    let tableData;
    if (needleValueSheet) {
      let data = getRespectiveSheetData(needleValueSheet.sheet, needleValueSheet.range);
      if (data && data.length >= 1) {
        needleValue = data.flat()[0];
      }
    }
    if (tableSheet) {
      tableData = getRespectiveSheetData(tableSheet.sheet, tableSheet.range);
    }
    return {
      needleValue: needleValue,
      tableData: tableData,
      index: Number(args[2]),
      isSort: parseBool(args[3])
    }
  }
  const respectiveSheet = data && data.find((sheet) => {
    return sheetName.split(" ").join("").toLowerCase() === sheet.name?.split(" ").join("").toLowerCase();
  }) || null;
  
  if (respectiveSheet && cellRangeArgs) {
    const [sri, sci, eri, eci] = cellRangeArgs;
    for (let row = sri; row <= eri; row++) {
      const rowData = [];
      for (let col = sci; col <= eci; col++) {
        const cell = respectiveSheet.rows[row]?.cells[col];
        if (cell) {
          rowData.push(cell.text);
        } else {
          rowData.push(""); // Cell is empty
        }
      }
      respectiveData.push(rowData);
    }
  } else {
    // console.log('okkkSheet not found');
  }
  return respectiveData;
}

const validateLookupFunction = (src) => {
  const lookupFunctions = ['VLOOKUP', 'HLOOKUP'];
  const closingParenthesisIndex = src.indexOf(')');
  const functionName = lookupFunctions.find(func => src.includes(func));
  const argsString = src.substring(src.indexOf('(') + 1, closingParenthesisIndex);
  const args = argsString.split(',');
  const singleRangeRegex = /^([$])?([a-zA-Z]{1,3})([$])?([1-9]\d{0,3}|[1-2]\d{3}|3000)$/;
  const rangeValidationRegex = /^([$])?([a-zA-Z]{1,3})([$])?([1-9]\d{0,3}|[1-2]\d{3}|3000):([$])?([a-zA-Z]{1,3})([$])?([1-9]\d{0,3}|[1-2]\d{3}|3000|(?!:[aA]$))$/;

  if (args.length < 4) {
    return '#ERROR!';
  }
  const sheetReferenceRegex = /([A-Za-z0-9_]+)!([A-Za-z]+[0-9]+)(:?[A-Za-z]+[0-9]+)?/g;
  const isOtherSheet = src.includes('!');
  let arg1 = args[0];
  let arg2 = args[1];
  if (isOtherSheet) {
    const [arg1SheetName, arg1Range] = arg1.split('!');
    const [arg2SheetName, arg2Range] = arg2.split('!');
    if (arg1Range && arg2Range) {
      arg1 = arg1Range;
      arg2 = arg2Range;
    }
  }
  if (!singleRangeRegex.test(arg1)) {
    // console.error('okkkError: Argument 1', arg1, 'of', args, 'is not in the correct format.');
    return '#ERROR!';
  } else if (!rangeValidationRegex.test(arg2)) {
    // console.error('okkkError: Argument 2', arg2, 'of', args, 'is not in the correct format.');
    return '#ERROR!';
  }
  return 'OK';
};
const getNeedleRangeValue = (needle, getCellText) => {
  let needleValueRange = expr2cellRangeArgs(needle.toUpperCase());
  let needleValue;
  if (needleValueRange) {
    const [sri, sci, eri, eci] = needleValueRange;
    needleValue = getCellText(sci, eri);
    return needleValue ? needleValue : '';
  }
}

const getRespectiveSheetData = (respectiveSheet, cellRangeArgs) => {
  let respectiveData = [];
  if (respectiveSheet && cellRangeArgs) {
    const [sri, sci, eri, eci] = cellRangeArgs;
    for (let row = sri; row <= eri; row++) {
      const rowData = [];
      for (let col = sci; col <= eci; col++) {
        const cell = respectiveSheet.rows[row]?.cells[col];
        if (cell) {
          rowData.push(cell.text);
        } else {
          rowData.push(""); // Cell is empty
        }
      }
      respectiveData.push(rowData);
    }
  }
  return respectiveData;
}
const getRespectiveSheet = (inputText, data) => {
  const [sheetName, dataRange] = inputText.split('!');
  const respectiveSheet = data.find((sheet) => {
    return sheetName.split(" ").join("").toLowerCase() === sheet.name.split(" ").join("").toLowerCase();
  });
  const cellRangeArgs = expr2cellRangeArgs(dataRange.toUpperCase());
  return {
    sheet: respectiveSheet,
    range: cellRangeArgs
  }
}
export default {
  render: cellRender,
};
