import React, { useState } from 'react'
// import {} from 'antd'
import {ReactComponent as ModesIcon} from '../../assets/modes.svg';
import {ReactComponent as DarkIcon} from '../../assets/Dark-Mode.svg';

import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';
import { useSpreadSheet } from '../../context/spreadsheet-context';
import { Tooltip } from 'antd';




function Modes() {
  const isMobile = window.screen.width < 769;

  const { spreadsheetInstanceRef } = useSpreadSheet();
  const { sheet } = spreadsheetInstanceRef.current;
  
  let toolBars = document.getElementsByClassName('x-spreadsheet-icon'); // Corrected class name
  let toolBarsArray = Array.from(toolBars);


    const [darkMode,setDarkMode]=useState(true)
    // console.log('darkMode : Out ',darkMode)

    const setMode=()=>{

        // console.log('darkMode : ',darkMode)
        // alert('Dark Mode')
        if(darkMode){
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            });
            toolBarsArray.forEach((tool) => {
              tool.style.filter = 'invert(.9) ';
            });
        }else{
            disableDarkMode();
            toolBarsArray.forEach((tool) => {
              tool.style.filter = 'none';
            });

        }
    sheet.table.render();

        setDarkMode(!darkMode)
      localStorage.setItem('isDarkMode', JSON.stringify(!darkMode));
    }
    return (
      <div>
        <Tooltip>
          <div title='Theme' onClick={setMode} style={{ height: 40, position: "relative", marginRight: "2px", top: isMobile ? "13px" : "7px", cursor: "pointer", zIndex: 1, opacity: .95, }} >
            {darkMode == !true ?
              <ModesIcon style={{ width: isMobile ? "20" : "27px", height: isMobile ? "20" : "27px" }} /> :
              <DarkIcon style={{ width: isMobile ? "22" : "29px", height: isMobile ? "22" : "29px" }} />}
          </div>
        </Tooltip>
      </div>
    )
}

export default Modes