import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { ChatBox } from "../../components/ChatBox/ChatBox";
import { useSpreadSheet } from "../../context/spreadsheet-context";
import {
  listenerSingularCellSelection,
  listenerMultipleCellSelection,
  listenerEditedCellData,
  listenerLoadImport,
  // listenerCellStyle,
  listenerAddSheet,
  listenerOnChanged,
  listenerClients,
  emitRoomID,
  listenerClientArrOnDisconnect,
  listenAddMember
  // emitOnChanged
} from "../../socket";
import MouseContainer from "../mousecontainer";
import { useDocument } from "./documentInfoContex";
import { workwiseServices } from "../../Workw-Api/api";
import { formatFileSize } from "../../utils/helper";


export const SpreadsheetContainer = ({
  setLoading,
  loading,
  connectedSocketID,
  clientSocketID,
  numberOfConnectedClients,
  serverX,
  serverY,
}) => {
  // const konn = "http://192.168.18.81:8080";
  const konn = "https://grid.workw.com";
  // const konn = "http://localhost:8080";
  const { id } = useParams();
  const { setCurrPos } = useSpreadSheet();
  const { setSheets, setDocumentInfo, setSavingData, sheets } = useDocument();

  const url_string = window.location.href; //window.location.href
  const url = new URL(url_string);
  const milegridURL = url.searchParams.get("gridname");
  let msg;

  const {
    spreadSheet_init,
    spreadsheetInstanceRef,
    onCellSelected,
    onCellsSelected,
    onCellEdited,
    onCellStyle,
    onAddSheet,
    onDeleteSheet,
    onRenameSheet
    //   onChanged,
  } = useSpreadSheet();
  React.useEffect(() => {
    spreadSheet_init();
    onCellSelected(id);
    onCellsSelected(id);
    onCellStyle();
    onAddSheet();
    onDeleteSheet(id,setSavingData);
    onRenameSheet(id,setSavingData);
    listenerAddSheet(spreadsheetInstanceRef);
    listenerEditedCellData(spreadsheetInstanceRef);
    onCellEdited(spreadsheetInstanceRef);
    listenerSingularCellSelection(spreadsheetInstanceRef,msg='COllab');
    listenerMultipleCellSelection(spreadsheetInstanceRef);
    listenerOnChanged(spreadsheetInstanceRef);
    listenerClients(spreadsheetInstanceRef);
    listenerClientArrOnDisconnect();
    listenerLoadImport(spreadsheetInstanceRef);
    listenAddMember(()=>{
      setTimeout(async () => {

        const response = await workwiseServices.getDocumentById(id);
        if (response?.status == 200 && response.data?.data?.rightType !== 0 && response.data?.data) {

          setDocumentInfo(response.data.data)
          console.log('listenAddMember');
        }


    }, 2000)}
    )
  }, [
    //  onChanged,
    setCurrPos,
    onCellSelected,
    onCellsSelected,
    onCellEdited,
    onCellStyle,
    onAddSheet,
    onDeleteSheet,
    onRenameSheet,
    spreadSheet_init,
    spreadsheetInstanceRef,
    id,
  ]);


  React.useEffect(() => {
    emitRoomID(id);
  }, [id]);

  React.useEffect(() => {
    const loadSpreadsheetData = async () => {
      try {
        document.getElementById("milegrid-url").innerHTML = milegridURL;
        const response = await axios.get(
          `${konn}/api/${id}`
        );
        // if (response.data[0].spreadsheetData.length > 1) {
        //   response.data[0].spreadsheetData = response.data[0].spreadsheetData.slice(1);
        // }
        let filteredSheetData;
        const getSheetData = response.data[0].spreadsheetData;
        // console.log("S",response.data);
        if (getSheetData) {
          filteredSheetData = getSheetData.filter(word => Object.keys(word).length > 0) || [];
          const size = new TextEncoder().encode(JSON.stringify(filteredSheetData)).length || 0;
          const sizeWithUnit = formatFileSize(size);
          setSavingData({ isSaving: '', lastSavedDateTime: '',fileSize:sizeWithUnit });
          if (filteredSheetData.length > 0) {
            spreadsheetInstanceRef.current.loadData(
              filteredSheetData
            );
            setSheets(filteredSheetData);
          }
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    loadSpreadsheetData();
  }, [id, spreadsheetInstanceRef,milegridURL]);

  React.useEffect(() => {
    // const sampleData = 
    // {
    //     "spreadsheetID": "xx",
    //     "spreadsheetData": [{
    //         "name": "Sheet 1",
    //         "freeze": "A1",
    //         "styles": [],
    //         "merges": [],
    //         "rows": {
    //             "len": 3000
    //         },
    //         "cols": {
    //             "len": 26
    //         },
    //         "validations": [],
    //         "autofilter": {}
    //     }],
    //     "readOnlySpreadsheet": "r.xx",
    //     "Comments": [],
    //     "__v": 1
    // };
    const createSpreadsheet = async () => {
      await axios.post(
        `${konn}/api/create`,
        {
          id: id,
          data: {},
        }
      );
    };
    createSpreadsheet();
  }, [id]);

  const [spreadsheetData, setSpreadsheetData] = React.useState([]);

  React.useEffect(() => {
    spreadsheetInstanceRef.current.change((data) => {
      // const newData = spreadsheetInstanceRef.current.getData();
      // console.log('on change FULL', newData);
      // console.log('on change HALF', data);
      setSpreadsheetData(data);
    });
  });



  React.useEffect(() => {
    // const offDataAPI = async (d) => {
    //   try {
    //     console.log('inOFFAPI', d);
    //     await axios.post(
    //       `${konn}/api/update`,
    //       {
    //         spreadsheetID: id,
    //         spreadsheetData: d,
    //       }
    //       );
    //     } catch (e) {
    //       console.log(e);
    //     }
    // };

    const updateSpreadsheet = async (data) => {
      // let updatedSheetData = data;
      // console.log('UPDATE')
      // if (localStorage.offlineData && navigator.onLine) {
      //   const temp = JSON.parse(localStorage.offlineData);
      //   // console.log(JSON.parse(localStorage.offlineData));
      //   console.log('temp', temp);
      //   for (let keys in temp) {
      //     offDataAPI(temp[keys]);
      //     console.log('loop: ', temp[keys]);
      //   }
      //   localStorage.clear();
      // }
      try {
        // online.
        await axios.post(
          `${konn}/api/update`,
          {
            spreadsheetID: id,
            spreadsheetData: data,
          }
        );
        if (data.length !== 0) {
          workwiseServices.saveDocumentlastUpdate(id, setSavingData, sheets);
        }
      } catch (e) {
        // when api failed to recover a response: no internet/ error
        console.log('NO NET')
        // if (!navigator.onLine) {
        //   // if offline
        //   // console.log(spreadsheetInstanceRef.current.getData());
        //   localStorage.setItem('offlineData', JSON.stringify(spreadsheetInstanceRef.current.getData()));
        //   const offData = localStorage.getItem('offlineData');
        //   console.log('catched: ', JSON.parse(offData));
        //   // console.log(navigator.onLine);
        // } else {
        //   // if online but still some error
        //   console.log(e);
        // }
      }
    };

    let timer = setTimeout(async () => {
      // console.log('on update', spreadsheetData);
      updateSpreadsheet(spreadsheetData);
    }, 100);

    return () => {
      clearTimeout(timer)
    };
  }, [spreadsheetInstanceRef, spreadsheetData, id]);

  return <div id="spreadsheet-container">
    <ChatBox />
    <MouseContainer/>
  </div>;
};
