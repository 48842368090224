
const hardcodeToken = null;

function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}

export function getWorkWiseAuthToken() {
  const searchParams = new URLSearchParams(document.location.search);
  let cookieToken = getCookieValue("token")
  let queryToken = searchParams.get("token");
  const finalToken = queryToken || cookieToken || hardcodeToken;
  return finalToken;
}

export function getSelectedData(sheet, selectedRange) {
  const { sri, sci, eri, eci } = selectedRange;
  const data = [];
  const headers = [];
  for (let ci = sci; ci <= eci; ci += 1) {
    const column = [];
    for (let ri = sri; ri <= eri; ri += 1) {
      const cell = sheet.data.getCell(ri, ci);
      const cellData = cell && typeof cell.text === 'string' ? cell.text.trim() : null;
      if (ri === sri) {
        headers.push(cellData || "");
        column.push(cellData || "");
      } else {
        column.push(cellData || "");
      }
    }
    if (column.length > 0) {
      data.push(column);
    }
  }
  let payload = {
    data: data,
    headers: headers
  }
  return payload;
}

export function findSheetName(sheets, text) {
  
  const sheetNames = sheets.map((sheet) => sheet.name);

  let maxCount = 0;
  const names = sheetNames && Array.isArray(sheetNames) && sheetNames.filter(name => name && name.includes(text));

  const counts = names.map(name => {
    const match = name.match(new RegExp(`${text} (\\d+)`));
    return match ? parseInt(match[1], 10) : 0;
  });

  maxCount = counts && Math.max(...counts);
  console.log('okkkk names', sheetNames, sheets,counts);

  let name = maxCount >= 0 ? `${text} ${maxCount + 1}` : `${text} 0`;

  return name;
}
export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};

